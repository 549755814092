let zh = {
  translations: {
    home: "首页",
    checkin: "签到",
    manage_booking: "管理预约",
    back: "返回",
    change: "更改",
    about_practice: "关于这个诊所",
    billing_policy: "收费信息",
    billing_policy_description: `所有患者都需要在问诊后支付全额问诊费用，目前标准咨询(15分钟)一共74.75澳元，含自费35.00澳元。 我们会为您向Medicare申请退还Medicare报销的费用。 16岁以下儿童凭有效Medicare证件无需自付费用。持有低保卡(concession card)的患者有折扣。`,
    cancellation_policy: "取消 / 失约",
    cancellation_policy_description: `如果您不能按时赴约，请
    致电我们的接待员取消或重新安排。 请注意，如果您在预约时间前2小时内取消或者失约， 我们将收取 39.75 澳元的费用。`,
    question1: `这个预约是给谁的？`,
    question1opt1: `我自己`,
    question1opt2: `别人`,
    question2: `病人以前在我们诊所就诊过吗？`,
    question2opt1: `没有（新病人）`,
    question2opt2: `是的（现有患者）`,
    question3: `病人需要什么类型的预约？`,
    about_you: "病人信息 - 请用英文填写",
    first_name: "名字",
    first_name_err_message: "名字必须填",
    last_name: "姓",
    last_name_err_message: "姓必须填",
    birth_date: "生日",
    birth_date_simple: "生日",
    birth_date_err_message: "生日必须填",
    phone_number: "电话号码",
    phone_number_err_message: "电话号码必须填",
    email: "电邮",
    email_err_message: "电邮必须填",
    address: "地址",
    street_address: "街道",
    street_address_err_message: "街道必须填",
    suburb: "城市",
    suburb_err_message: "城市必须填",
    postcode: "邮编",
    postcode_err_message: "邮编必须填",
    read_and_accept_term_and_condition: `我已阅读并接受条款和条件。`,
    read_and_accept_term_and_condition_feedback: `您必须在提交前同意。`,
    accept_term_and_condition: `请接受条款和条件`,
    continue: "继续",
    more_appointment: "更多预约",
    appointment_confirmed: `您的预约已确认！`,
    forward_medical_gp_clinic: `Forward Medical全科医生诊所`,
    close: "关闭",
    help_and_support: "帮助和支持",
    privacy_and_policy: "隐私政策",
    term_of_use: "使用条款",
    all_right_reserved: `2023 NextAppointment 版权所有`,
    no_appointment_available: "没有可预约的时间",
    morning: "上午",
    afternoon: "下午",
    next: "继续",
    speak: "说",
    type_unavailable: "对不起您选择的预约类型需要更长时间,",
    appt_type_message: "时间不够， 请您另外选择一个时间。",
    appt_time: "对不起您所选时间",
    appt_not_avail: "已经被别人约走，请您另外选择一个时间。",
    book_your_next: "预订您的下一个",
    healthcare_appointment: "医疗保健预约。",
    find_pactitioners: "查找、预订您最喜欢的医生并将其添加到您的保健团队中。",
    book_appointment_now: "立即预约",
    search: "搜索",
    practices: "诊所",
    practitioners: "医生",
    popular_practices: "热门诊所",
    at: "的",
    yes: "确认",
    no: "取消",
    no_appointments_available: "没有时间可以预约",
    general_error_title: "系统错误",
    warning_title: "提示",
    general_error_prefix:
      "对不起我们无法链接您诊所的预约系统，请您给诊所直接打电话",
    general_error_suffix: "预约.",
    we_speak_your_language: "我们会说你的语言",
    confirm_details_QA: "确认您的预约信息",
    appointment_type: "预约类型",
    time: "时间",
    date: "日期",
    doctor: "医生",
    clinic: "诊所",
    go_back_make_change: "返回并进行更改",
    confirm_details: "确认",
    set: "編輯",
    january: "一月",
    february: "二月",
    march: "三月",
    april: "四月",
    may: "五月",
    june: "六月",
    july: "七月",
    august: "八月",
    september: "九月",
    october: "十月",
    november: "十一月",
    december: "十二月",
    day_abbr: "日",
    month_abbr: "月",
    year_abbr: "年",
    select_option: "请输入城市名",
    state: "州",
    privacy_policy: "隐私政策",
    p_intro: "介绍",
    p_introduction:
      "本隐私政策规定了我们收集、存储、使用和披露您的个人信息的方式和原因，以及如果您对我们如何处理您的个人信息有任何疑问时如何联系我们。",
    p_information: "我们收集哪些个人信息？",
    p_info_collect: "我们将收集的有关您的信息包括：",
    your_name: "你的名字",
    your_DOB: "您的出生日期",
    your_contact_detail: "您的联系方式（例如地址、电子邮件地址、电话号码）",
    your_gender: "您的性别和/或出生性别",
    occupation: "职业",
    cultural_bg: "文化背景",
    your_emg_detail: "您的紧急联系方式",
    your_kin_detail: "您的近亲详细信息",
    your_app_request: "您要求的预约类型",
    your_app_reason: "您寻求此类约会的原因",
    your_notes: "您提供的与健康预约相关的笔记、记录和图像",
    your_health: "有关您的私人健康保险基金的信息，包括您的会员编号",
    your_medicare:
      "您的 Medicare、Pension、Health Care Card 和 Veteran Affairs 编号和详细信息",
    q_anonymous: "匿名与我们打交道？",
    ans_anonymous:
      "您有权匿名或以假名与我们打交道，除非我们这样做不切实际，或者法律要求或授权我们只能与已识别的个人打交道。",
    ps_info: "我们如何收集您的个人信息",
    ans_info: "通过我们的网站和相关的预订应用程序。",
    ps_share_info: "我们与谁分享您的个人信息？",
    ans_share_info:
      "我们只会与您在预约时预约的诊所分享您的个人信息。 完成预订后，不会共享或存储任何信息。",
    ps_protect: "我们如何存储和保护您的个人信息？",
    ans_protect:
      "您的个人信息以电子方式存储在您的诊所或您用于预约的个人设备中。 我们不会在我们的服务器中存储您的任何个人信息，所有从您的设备传输的信息和您的练习都会在您的预订被确认或未进行后被加密和丢弃。",
    your_complaint: "您如何提出与隐私相关的投诉，投诉将如何处理？",
    ans_complaint_1:
      "我们认真对待有关隐私的投诉和疑虑。 您应该以书面形式表达您可能有的任何隐私问题。 然后，我们将尝试根据我们的决议程序解决它。 请通过电子邮件给我们写信 ",
    ans_complaint_2:
      ", 邮件（隐私官，50 Hutt Street Adelaide 5000）。 我们将在 30 天内处理您的投诉，并以书面形式回复您。",
    contact_info_1:
      "您也可以联系 OAIC。 通常，OAIC 会要求您给他们时间做出回应，然后他们才会展开调查。 欲了解更多信息，请访问",
    contact_info_2: "或致电 1300 336 002 联系 OAIC。",
    dowanload_app_book_your_next: "预约医生更快更简单，立刻下载",
    dowanload_app_appointment: "下次有约",
    scan_and_dowanload_app: "扫描二维码立即下载APP",
    dowanload_app_main_book_your_favorite:
      "快速预定全科医生，牙医，理疗师，都在",
    doanload_app_main_pactitioners: "",
    dowanload_app_main_app: "app",
    dowanload_app_main_availabe: "适用于 iOS 和 Android",
    dowanload_app_button: "立刻下载APP",
    manage_appointment: "管理预约",
    book_appointment: "预约",
    appointments: "您的预约",
    with: "和",
    for: "为了",
    check_in: "签到",
    confirm_appointment: "确认预约",
    cancel_appointment: "取消预约",
    confirm_cancellation: "确认取消",
    contact_clinic_msg: "没有预约记录！",
    account_list_msg: "没有收费记录！",
    required_msg: "此字段是必需的！",
    submit: "确认",
    confirm_msg: "确认赴约？",
    cancel_confirm_msg: "您的预约已成功取消！",
    thank_you_msg: "谢谢您确认赴约！",
    review_msg:
      "一旦您的情况清楚，请立即告知我们，我们友好的接待员可能会在预约前给您打电话。",
    not_sure: "没有把握",
    confirm_ans: "取消预约？",
    keep_appointment: "保持预约",
    thank_You_check_in: "谢谢，请在我们的等候室等待您的医生！",
    assist_msg: "对不起，请去诊所前台签到！",
    total: "小计",
    invoice_res_1: "您所请求的帐户已发送至您的注册邮箱",
    invoice_res_2:
      "抱歉，我们目前无法处理您的请求，请联系我们友好的接待员寻求帮助。",
    invoice_res_3:
      "在索取任何帐户信息之前，请致电我们友好的接待员注册您的电子邮件",
    invoice_res_4:
      "抱歉，我们在系统中找不到您的帐户信息，如果需要更多信息，请联系您的诊所",
    clinic_no_found:
      "抱歉，我们在系统中找不到该诊所，如果需要更多信息，请联系您的诊所",
    account: "收费记录",
    account_list: "收费记录",
    invoice_id: "发票号",
    provider: "医生",
    service: "治疗项目",
    mbs_item: "MBS单号",
    fee: "应费款",
    paid: "已付款",
    email_a_copy: "通过电子邮件发送副本",
    bulk_billed: "全额报销",
    unavailable_tooltip: "该类预约需要与诊所电话预定！",
    blacklist_message: "抱歉，我们的预订系统出现问题，请稍后再试。",
    status: "状态",
    label_yes: "是",
    label_no: "否",
    has_patient_phone: "绿色通道 - 输入手机号码认证后自动填写下面的表格。",
    phone_number_heading:
      "备注：如果您帮家人或朋友预定，请输入病人的电话号码。",
    retrieve: "走绿色通道",
    verification_code: "验证码",
    verification_code_err_message: "请输入有效的验证码",
    send_verification_code: "发送验证码",
    verify: "核实",
    code_error: "您输入的验证码不正确，请查实。",
    phone_error: "对不起，我们的系统出现问题，请联系您诊所。",
    text_message_sent: "已发送短信至",
    with_verification_code: "附带验证码",
    change_phone_number: "更改电话号码",
    enter_patient_info: "手动输入患者信息",
    select_patient: "请选择一位患者",
    other_patient: "其他病人",
    appointment_book_for: "患者",
    no_patient_found: "未找到患者",
    has_patient_phone_with: "通过电话获取患者信息更快捷。",
  },
};

export default zh;
