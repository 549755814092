import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../common/Loader";
import { useParams } from "react-router-dom";

const VerifyPhone = ({
  setStep,
  loaders,
  setLoaders,
  setApiError,
  verify,
  setVerify,
  setPatientList,
}) => {
  const { t } = useTranslation();
  const { clinicCode } = useParams();

  useEffect(() => {
    setPatientList([]);
  }, []);

  const handleBack = () => {
    setStep(0);
  };

  const handleCodeChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "").slice(0, 6);
    setVerify((prev) => ({
      ...prev,
      code: {
        value: sanitizedValue,
        isInValid: sanitizedValue.length !== 6,
      },
    }));
  };

  const handleSendVerificationCode = () => {
    const sanitizedValue = verify.code.value.replace(/[^0-9]/g, "").slice(0, 6);
    if (sanitizedValue.length !== 6) {
      setVerify((prev) => ({
        ...prev,
        code: {
          ...prev["code"],
          isInValid: true,
        },
      }));
      return;
    }
    setLoaders((prev) => ({ ...prev, code: true }));
    fetch(`/server/patient/verify/code`, {
      method: "PUT",
      body: JSON.stringify({
        phoneNumber: verify.phoneNumber.value,
        code: verify.code.value,
      }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.match === "YES") {
          fetch(
            // `/server/patient/list/DEV/0409565133`
            `/server/patient/list/${clinicCode}/${verify.phoneNumber.value}`
          )
            .then((res) => res.json())
            .then((data) => {
              setPatientList(data);
              setLoaders((prev) => ({ ...prev, code: false }));
            })
            .catch(() => {
              setApiError((prev) => ({ ...prev, code: true }));
              setLoaders((prev) => ({ ...prev, code: false }));
            });
          setStep(3);
          return;
        }
        setApiError((prev) => ({ ...prev, code: true }));
      })
      .catch(() => {
        setApiError((prev) => ({ ...prev, code: true }));
        setLoaders((prev) => ({ ...prev, code: false }));
      });
  };

  const handleEnterPatient = () => {
    setStep(0);
  };

  const handlePhone = () => {
    setVerify((prev) => ({
      ...prev,
      phoneNumber: {
        value: "",
        isInValid: false,
      },
    }));
    setStep(1);
  };
  return (
    <>
      <div className="appoi-nav-main">
        <div className="appoi-nav-inner-main">
          <button onClick={handleBack} className="btn back-button">
            <i className="fas fa-arrow-left filled"></i>
            <span>{t("back")}</span>
          </button>
        </div>
      </div>
      <div className="appoi-detail-inner-right">
        <div className="d-flex justify-content-center align-items-center gap-4 py-2">
          <h5>{`${t("text_message_sent")} ${verify.phoneNumber.value} ${t(
            "with_verification_code"
          )}`}</h5>
        </div>
        <div className="row">
          <div className="col-xl-7 col-lg-10 my-3 mx-auto">
            <div className="w-100 mb-3">
              <Form.Label className="text-center">
                {t("verification_code")}
              </Form.Label>
              <div className="d-flex flex-column flex-md-row gap-2 justify-content-center align-items-start">
                <div className="w-100 ">
                  <Form.Control
                    className="form-control"
                    onChange={handleCodeChange}
                    value={verify.code.value}
                    name="code"
                    type="text"
                    required
                    autoFocus
                    isInvalid={verify.code.isInValid}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="w-100 text-center"
                  >
                    {t("verification_code_err_message")}
                  </Form.Control.Feedback>
                </div>
                <button
                  type="button"
                  className="border btn activeBtn verify-btn text-white my-0"
                  style={{ minWidth: "150px", minHeight: "47px" }}
                  onClick={handleSendVerificationCode}
                  disabled={verify.code.isInValid}
                >
                  {loaders.code ? (
                    <Loader color="light" size="sm" />
                  ) : (
                    t("confirm_details")
                  )}
                </button>
              </div>
            </div>

            <div className="d-flex flex-column flex-md-row gap-2 justify-content-center">
              <button
                type="button"
                className="border btn activeBtn verify-btn text-white w-md-75"
                onClick={handlePhone}
              >
                {t("change_phone_number")}
              </button>
              <button
                type="button"
                className="border btn activeBtn verify-btn text-white w-100"
                onClick={handleEnterPatient}
              >
                {t("enter_patient_info")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyPhone;
