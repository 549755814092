import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from '../common/Loader';

const SelectPatient = ({ setStep, patientList, setSelectedPatient, selectedPatient, validated, handleSubmit, setVerify, loaders }) => {
    const { t } = useTranslation();
    const handleBack = () => {
        setSelectedPatient({ patientId: "" })
        setStep(0)
    }

    const handleEnterPatient = () => {
        setSelectedPatient({ patientId: "" })
        setStep(0)
    }

    const handlePhone = () => {
        setVerify((prev) => ({
            ...prev,
            phoneNumber: {
                value: "",
                isInValid: false,
            },
        }));
        setSelectedPatient({ patientId: "" })
        setStep(1)
    }

    const handlePatientClick = (data) => {
        setSelectedPatient(data);
    };

    useEffect(() => {
        setSelectedPatient({ patientId: "" })
    }, [])
    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="appoi-nav-main">
                <div className="appoi-nav-inner-main">
                    <button
                        onClick={handleBack}
                        className="btn back-button"
                    >
                        <i className="fas fa-arrow-left filled"></i>
                        <span>{t("back")}</span>
                    </button>
                </div>
            </div>
            <div className="appoi-detail-inner-right">
                <div className="d-flex align-items-center gap-4 py-2">
                    <h5>{`${t("select_patient")}`}</h5>
                </div>

                <div className="row gy-3 pb-4">
                    {loaders.code ?
                        (<div className="col-12 text-center">
                            <p>
                                <Loader color="primary" size="sm" />
                            </p>
                        </div>) : patientList.length === 0 ? (
                            <div className="col-12 text-center">
                                <p className="text-muted">{t("no_patient_found")}</p>
                            </div>
                        ) : (
                            patientList.map((pat, key) => (
                                <div
                                    className={`patient-name col-lg-3 col-md-4 col-sm-4 col-6`}
                                    key={key}
                                >
                                    <div>
                                        <button
                                            type="button"
                                            className={`border btn w-100 ${pat.patientId === selectedPatient.patientId
                                                ? "activeBtn text-white"
                                                : ""
                                                }`}
                                            onClick={() => handlePatientClick(pat)}
                                        >
                                            {`${pat.firstName || ""} ${pat.lastName || ""}`}
                                        </button>
                                    </div>
                                </div>
                            ))
                        )}
                </div>

                <div className="terms-accept my-3">
                    <div className="custom-checkbox">
                        <Form.Check
                            required
                            name="termsAccept"
                            type="checkbox"
                            id="terms_accept"
                            label={t("read_and_accept_term_and_condition")}
                            feedback={t("read_and_accept_term_and_condition_feedback")}
                            feedbackType="invalid"
                            disabled={!patientList.length}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t("accept_term_and_condition")}
                        </Form.Control.Feedback>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row gap-2">
                    <button
                        type="submit"
                        className="border btn w-100 activeBtn verify-btn text-white"
                        disabled={!selectedPatient.patientId}
                    >
                        {t("continue")}
                    </button>
                    <button
                        type="button"
                        className="border btn w-100 activeBtn verify-btn text-white"
                        onClick={handlePhone}>
                        {t("change_phone_number")}
                    </button>
                    <button
                        type="button"
                        className="border btn w-100 activeBtn verify-btn text-white"
                        onClick={handleEnterPatient}>
                        {t("enter_patient_info")}
                    </button>
                </div>
            </div>
        </Form>
    );
}

export default SelectPatient;
