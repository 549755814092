import React, { useCallback, useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import BookingSlots from "./shared/slot-group.jsx";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  convertDate,
  convertDateTime,
  convertTime,
  dateLanguage,
  formatDate,
  formatDateString,
  formatLongDate,
  isValidDate,
  longDateFormat,
} from "../../lib/dateutils.js";
import { groupSlots, isTypeAvailable } from "../../lib/slotutils.js";
import BookingForm from "../bookingForm";
import { Button, Form, Modal } from "react-bootstrap";
import Spinner from "./shared/spinner.jsx";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import isUndefined from "lodash/isUndefined";
import AlertModal from "../common/AlertModal";
import { get, isArray } from "lodash";
import UnavailableTooltip from "./shared/unavailable-tooltip.jsx";
import Loader from "../common/Loader.js";
import SendVerification from "./send-verification.jsx";
import VerifyPhone from "./verify-phone.jsx";
import SelectPatient from "./select-patient.jsx";
const _ = { isUndefined };

const quizJsonData = (t) => {
  let json = [
    // {
    //   id: 0,
    //   question: t("question1"),
    //   options: [
    //     { code: "MySelf", description: t("question1opt1") },
    //     { code: "Someone else", description: t("question1opt2") },
    //   ],
    //   linkId: "0",
    // },
    {
      id: 1,
      question: t("question2"),
      options: [
        { code: "N", description: t("question2opt1") },
        { code: "Y", description: t("question2opt2") },
      ],
      linkId: "1",
    },
    {
      id: 2,
      question: t("question3"),
      linkId: "2",
    },
  ];
  return json;
};

const defaultVerify = {
  phoneNumber: { value: "", isInValid: false },
  code: { value: "", isInValid: false },
};
const defaultSelectedPatient = { patientId: "" };

const Slot = ({
  startDate,
  endDate,
  selectorFormDetails,
  setSelectorFormDetails,
}) => {
  const navigate = useNavigate();
  const router = useLocation();
  const { clinicCode, doctorCode, slotTime, length, clinicId } = useParams();
  const { t } = useTranslation();
  let locale = localStorage.getItem("locale");
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showBlackListAlertModal, setShowBlackListAlertModal] = useState(false);
  const [doctorData, setDoctorData] = useState();
  const [clinicData, setClinicData] = useState();
  const [fromDate, setFromDate] = useState();
  const [slotGroups, setSlotGroups] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [allSlotData, setAllSlotData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [step, setStep] = useState(0);
  const [practitionerCode, setPractitionerCode] = useState();
  const [currentOpt, setCurrentOpt] = useState();
  const [quizData, setQuizData] = useState(quizJsonData(t));
  const [birthdate, setBirthDate] = useState("");
  const [bookingDetails, setBookingDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    streetAddress: "",
    suburb: "",
    postcode: "",
  });
  const [validated, setValidated] = useState(false);
  const [practitioners, setPractitioners] = useState([]);
  const [showPractitioner, setShowPractitioner] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const date = slotTime && convertDateTime(slotTime);
  const [isLengthChecked, setIsLengthChecked] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [searchDetails, setSearchDetails] = useState({
    postcode: null,
    practitioner: null,
  });
  const [checkLength, setCheckLength] = useState(false);
  const [checkDetails, setCheckDetails] = useState(true);
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [apiError, setApiError] = useState({ phone: false, code: false });
  const [loaders, setLoaders] = useState({ phone: false, code: false });
  const [verify, setVerify] = useState(defaultVerify);
  const [selectedPatient, setSelectedPatient] = useState(
    defaultSelectedPatient
  );
  const [patientList, setPatientList] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (selectedPatient.patientId) {
      setBookingDetails({ ...selectedPatient });
    } else {
      setBookingDetails({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: "",
        streetAddress: "",
        suburb: "",
        postcode: "",
      });
    }
  }, [selectedPatient]);

  const handleCheckLengthOpen = () => {
    setCheckLength(true);
  };
  const handleCheckLengthClose = () => {
    setCheckLength(false);
    setCurrentOpt();
    setIsLengthChecked(false);

    delete quizData?.[1].ans;
    setQuizData([...quizData]);
  };
  const handleClose = () => {
    setShowPractitioner(false);
    setPractitionerCode(doctorCode);
  };

  const handleContinue = () => {
    let selectedDoctor = practitioners.find(
      (item) => item.doctorCode === practitionerCode
    );
    quizData[1] = { ...quizData[1], options: selectedDoctor.appointmentTypes };
    setQuizData(quizData);
    setDoctorData(selectedDoctor);
    let searchParams =
      typeof router.search != "undefined" ? `${router?.search}` : "";
    navigate(
      `/${locale}/booking/slots/${clinicCode}/${selectedSlot.doctorCode}/${clinicId}/${selectedSlot.date}/${selectedSlot.length}${searchParams}`
    );

    handleClose();
    const newQuizArr = quizData.map(({ ans, ...rest }) =>
      ans ? { ...rest } : { ...rest }
    );
    setQuizData(newQuizArr);
    setCurrentQuestion(1);
    setCurrentOpt();
  };

  const [showCheckAppointment, setShowCheckAppointment] = useState(false);
  const handleCloseAppointment = () => setShowCheckAppointment(false);
  const handleShowAppointment = () => setShowCheckAppointment(true);
  const [bDate, setBDate] = useState({
    day: "",
    month: "",
    year: "",
  });

  const handleDateChange = (e, name) => {
    setBDate((prev) => {
      return {
        ...prev,
        [name.name]: name.name === "month" ? e.label : e.value,
      };
    });
  };

  let searchParams =
    typeof router.search != "undefined" ? `${router?.search}` : "";

  const handlePostCodeChange = (selected) => {
    setSearchDetails({
      ...searchDetails,
      postcode: { ...selected, label: selected.label.split(",")[0] },
    });
  };

  const getPostCode = (inputText) => {
    return fetch(`/server/lookup/postcode/${inputText}`)
      .then((res) => res.json())
      .then((data) => {
        return data.map((item) => ({
          value: item.id,
          label: `${item.suburb}, ${item.state}, ${item.postcode}`,
          state: item.state,
          postcode: item.postcode,
        }));
      });
  };

  const promiseAddressOptions = useCallback(
    debounce((inputValue, callback) => {
      getPostCode(inputValue).then((options) => callback(options));
    }, 1000),
    []
  );

  useEffect(() => {
    let data = quizJsonData(t);
    if (selectorFormDetails?.quizData) {
      setCurrentQuestion(3);
      setQuizData(
        selectorFormDetails?.quizData?.map((item, idx) => ({
          ...item,
          ...data[idx],
        }))
      );
      setBookingDetails(selectorFormDetails.payload);
      setBirthDate(selectorFormDetails.payload.birthdate);
      setSearchDetails(selectorFormDetails.payload.postcode);
    } else {
      setQuizData(quizData.map((item, idx) => ({ ...item, ...data[idx] })));
    }
  }, [t, practitioners]);

  useEffect(() => {
    if (selectorFormDetails?.quizData) {
      setQuizData(selectorFormDetails?.quizData);
      setCurrentQuestion(3);
      setCurrentOpt(selectorFormDetails?.quizData[1].ans);
    }
  }, [selectorFormDetails?.quizData]);

  useEffect(() => {
    setValidated(false);
    if (step === 0) {
      window.location.replace("#form");
    }
  }, [step]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (!form.checkValidity() || !isValidDate(birthdate)) {
      return;
    }
    setCheckDetails(false);
  };

  const handleExistingSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (!form.checkValidity()) {
      return;
    }
    setCheckDetails(false);
  };

  const handleCheckContinue = async (e) => {
    e.preventDefault();

    fetch(`/server/lookup/blacklist/${clinicId}`)
      .then((response) => response.json())
      .then((res) => {
        const isBlackListed = res?.find(
          (i) => i?.phoneNumber === bookingDetails?.phoneNumber
        );
        if (isBlackListed) {
          setShowBlackListAlertModal(true);
          return;
        }
        if (checkDetails) {
          return;
        }

        let app_length = length;
        if (quizData?.[1]?.ans?.length > length) {
          app_length = quizData?.[1]?.ans?.length;
        }
        if (selectedPatient.patientId) {
          bookingDetails.phoneNumber = verify.phoneNumber.value;
        }
        let payload = {
          ...bookingDetails,
          suburb: searchDetails?.postcode?.label,
          state: searchDetails?.postcode?.state,
          postcode: searchDetails?.postcode?.postcode,
          doctorCode: doctorCode,
          date: slotTime,
          length: app_length,
          practiceId: clinicCode,
          existingPatientFlag: quizData?.[0]?.ans?.code,
          appointmentType: quizData?.[1]?.ans?.code,
          dateOfBirth: birthdate.split("/").join(""),
        };
        setConfirmBtnLoading(true);
        fetch("/server/appointment/add", {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-type": "application/json",
          },
        })
          .then((data) => data.json())
          .then((resp) => {
            setConfirmBtnLoading(false);
            setSelectorFormDetails({
              payload: { ...payload, birthdate, postcode: searchDetails },
              quizData,
            });

            if (!_.isUndefined(resp.appointmentId)) {
              if (Number(resp.appointmentId) === -1) {
                handleShowAppointment();
              } else {
                navigate(`/${locale}/booking/success${searchParams}`, {
                  state: {
                    doctor: { doctorData },
                    clinic: { clinicData },
                    appointment: { slotTime },
                  },
                });
              }
            } else {
              setShowAlertModal(true);
            }
          })
          .catch((err) => {
            setSelectorFormDetails({
              payload: { ...payload, birthdate, postcode: searchDetails },
              quizData,
            });
            console.log("err", err);
          });
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      });
  };

  let formattedStart = formatDate(startDate);
  let formattedEnd = formatDate(endDate);

  useEffect(() => {
    fetch(`/server/practitioner/list/${clinicId}/${locale}`)
      .then((res) => res.json())
      .then((data) => {
        if (isArray(data) && data.length > 0) {
          setPractitioners(data);
          let selectedDoctor = data.find(
            (item) => item.doctorCode === doctorCode
          );
          quizData[1] = {
            ...quizData[1],
            options: selectedDoctor.appointmentTypes,
          };
          if (selectorFormDetails?.quizData) {
            setCurrentQuestion(3);
            setQuizData(selectorFormDetails?.quizData);
          } else {
            setQuizData(quizData);
          }
          setDoctorData(selectedDoctor);
        } else {
          setShowAlertModal(true);
        }
      });
  }, [locale]);

  useEffect(() => {
    fetch(`/server/clinic/contact/${clinicId}/${locale}`)
      .then((res) => res.json())
      .then((data) => {
        if (!_.isUndefined(data.clinicId)) {
          setClinicData(data);
        } else {
          setShowAlertModal(true);
        }
      });
    getPolicyDetails();
  }, [locale]);

  useEffect(() => {
    if (doctorCode) {
      setBookingDetails({ ...bookingDetails, doctorCode: doctorCode });
      setBDate({ day: bDate.day, month: bDate.month, year: bDate.year });
    }
  }, [doctorCode]);

  const fetchSlotGroups = (docCode, startTime, endTime) => {
    setLoading(true);
    fetch(
      `/server/appointment/practitioner/${clinicCode}/${docCode}/${startTime}/${endTime}${searchParams}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (isArray(data) && data.length > 0) {
          setAllSlotData(data);
          setSlotGroups(groupSlots(data, startDate, endDate));
        } else {
          setShowAlertModal(true);
          setSlotGroups([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setShowAlertModal(true);
        console.error("err", err);
      });
  };

  const getPolicyDetails = () => {
    fetch(`/server/clinic/policy/${clinicId}/${locale}`)
      .then((res) => res.json())
      .then((res) => {
        setPolicyData(res);
      });
  };

  useEffect(() => {
    fetchSlotGroups(doctorCode, formattedStart, formattedEnd);
  }, []);

  useEffect(() => {
    setPractitionerCode(doctorCode);
  }, []);

  useEffect(() => {
    if (slotTime) {
      let slot = allSlotData.find((i) => i.date === slotTime);
      setSelectedSlot(slot);
    }
  }, [slotTime, allSlotData]);

  let checker = (arr, target) =>
    target.every((v) => arr.includes(v.toString()));

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  const timeAdder = (date, min) => {
    let day = date.substring(0, 2);
    let month = date.substring(2, 4);
    let year = date.substring(4, 8);
    let hour = date.substring(8, 10);
    let minute = date.substring(10, 12);

    let theDate = new Date(year, month - 1, day, hour, minute, 0, 0);

    const d = new Date(theDate.toString());
    d.setMinutes(d.getMinutes() + min);
    let y = d.getFullYear();
    let m = addZero(d.getMonth() + 1);
    let dt = addZero(d.getDate());
    let h = addZero(d.getHours());
    let mins = addZero(d.getMinutes());
    let time = dt + "" + m + "" + y + "" + h + "" + mins;
    return time;
  };

  const checkAnswer = (data, opt, index) => {
    let isStep = false;
    if (data.id === 2 && opt.length / Number(length) > 1) {
      let date = formatLongDate(convertDate(slotTime));
      let currDateSlots = slotGroups.find(
        (item) => item.period === date
      )?.slots;
      const arr = Array.from(
        { length: opt.length / Number(length) },
        (_, index) => slotTime
      );
      let arr1 = arr.map((item, index) =>
        timeAdder(item, Number(length) * index)
      );
      let isChecker = checker(
        currDateSlots.map((item) => item.date),
        arr1
      );
      if (isChecker) {
        setQuizData(
          quizData.map((item) =>
            item.id === data.id ? { ...item, ans: opt } : item
          )
        );
        setCurrentOpt(opt);
      } else {
        isStep = true;
        handleCheckLengthOpen();
        setIsLengthChecked(true);
      }
    }
    if (index !== 2) {
      setCurrentQuestion(index + 1);
      // if (index === 1) {
      // if (opt.code !== "Y") {
      // } else {
      //   if (index < currentQuestion) setCurrentQuestion(index);
      // }
      // } else {
      // setCurrentQuestion(index + 1);
      // }
    } else {
      if (!isStep) {
        if (
          !selectedPatient.patientId ||
          selectedPatient.patientId === "other"
        ) {
          setCurrentQuestion(
            index === currentQuestion ? index + 1 : currentQuestion
          );
        }
      }
    }
    const newQuizData = quizData.map((item) => {
      if (item.id === data.id) {
        return { ...item, ans: opt };
      } else if (data.id < item.id) {
        delete item.ans;
        return item;
      } else {
        return item;
      }
    });
    setQuizData(newQuizData);
    setCurrentOpt(opt);
    setBookingDetails({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      streetAddress: "",
      suburb: "",
      postcode: "",
    });
    setValidated(false);
    setBirthDate("");
    setBDate({
      day: "",
      month: "",
      year: "",
    });
    setSearchDetails({
      postcode: null,
      practitioner: null,
    });
  };

  useEffect(() => {
    if (currentQuestion !== 1) {
      window.location.replace(`#${currentQuestion}`);
    }
    if (currentQuestion === 3) {
      window.location.replace("#form");
    }
  }, [currentQuestion]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleChangeClick = () => {
    setPractitionerCode(doctorCode);
    setShowPractitioner(true);
    if (fromDate) {
      let date = new Date(fromDate);
      let newDate = new Date(fromDate);
      newDate.setDate(newDate.getDate() + 30);
      fetchSlotGroups(doctorCode, formatDate(date), formatDate(newDate));
    } else {
      fetchSlotGroups(doctorCode, formattedStart, formattedEnd);
    }
  };

  const handlePractitionerChange = (e) => {
    // let selectedDoctor = practitioners.find(
    //   (item) => item.doctorCode === e.target.value
    // );
    // quizData[2] = { ...quizData[2], options: selectedDoctor.appointmentTypes };
    // setQuizData(quizData);
    // setDoctorData(selectedDoctor);
    if (fromDate) {
      let date = new Date(fromDate);
      let newDate = new Date(fromDate);
      newDate.setDate(newDate.getDate() + 30);
      fetchSlotGroups(e.target.value, formatDate(date), formatDate(newDate));
    } else {
      fetchSlotGroups(e.target.value, formattedStart, formattedEnd);
    }
    // let searchParams =
    //   typeof router.search != "undefined" ? `${router?.search}` : "";
    // navigate(
    //   `/${locale}/booking/slots/${clinicCode}/${e.target.value}/${clinicId}/${slotTime}/${length}${searchParams}`
    // );
    setPractitionerCode(e.target.value);
  };

  const onDateChange = (e) => {
    setFromDate(e.target.value);
    let date = new Date(e.target.value);
    let newDate = new Date(e.target.value);
    newDate.setDate(newDate.getDate() + 30);
    fetchSlotGroups(practitionerCode, formatDate(date), formatDate(newDate));
  };

  const handleGoBackSlots = () => {
    handleCloseAppointment();
    navigate(
      `/${locale}/booking/doctors/${clinicCode}/${clinicId}${searchParams}`
    );
  };

  const dateDayNum = () => {
    const date = dateLanguage(
      longDateFormat(convertDate(slotTime).toString()),
      locale
    );

    return new Date(date).getDay();
  };

  const isBookDisable = () => {
    const isDisable = slotGroups.length === 0 || Boolean(!selectedSlot);
    return isDisable;
  };

  const handleRetrieve = () => {
    setVerify(defaultVerify);
    setStep(1);
    scrollToTop();
  };

  const handleGoBack = () => {
    setValidated(false);
    setCheckDetails(true);
  };

  return (
    <div>
      <section className="appoi-detail-section appoi-detail-section-form-conf">
        <div className="appoi-detail-inner-section">
          <div className="row m-0">
            {checkDetails ? (
              <div className="col-md-4 col-xl-3">
                <div className="appoi-detail-left">
                  <div className="appoi-detail-inner-left">
                    <div className="booking-doc-info">
                      <Link
                        to={`/patient/doctor-profile${searchParams}`}
                        className="booking-doc-img"
                      >
                        <img
                          src={
                            `/assets/images/doctors/` +
                            `${doctorData?.doctorImg}`
                          }
                          alt="User"
                        />
                      </Link>
                      <div className="booking-info">
                        <h4>
                          <Link to={`/patient/doctor-profile${searchParams}`}>
                            {doctorData?.doctorName}
                          </Link>
                        </h4>
                        <div className="inner-box-book">
                          <p className="text-muted mb-2">
                            <i className="fas fa-map-marker-alt"></i>
                            {clinicData?.address}
                          </p>
                          <div className="time-content">
                            {slotTime ? (
                              <>
                                <div className="d-flex align-items-center mb-2">
                                  <i
                                    className="fa fa-clock-o me-2"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  <h5 className="mb-0">
                                    {convertTime(slotTime).toString()}
                                  </h5>
                                </div>
                                <p>
                                  <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                  ></i>
                                  {dateLanguage(
                                    longDateFormat(
                                      convertDate(slotTime).toString()
                                    ),
                                    locale
                                  )}
                                  {/* {formatDateString(slotTime)} */}
                                </p>
                              </>
                            ) : null}
                          </div>
                        </div>
                        {slotTime ? (
                          <button
                            onClick={() => handleChangeClick()}
                            className="ct-apt-btn"
                          >
                            {t("change")}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className={`col-md-8 col-xl-9 ${checkDetails ? "" : "mx-auto"}`}
            >
              {checkDetails ? (
                <div className="appoi-detail-right">
                  {step === 0 && (
                    <>
                      <div className="appoi-nav-main">
                        <div className="appoi-nav-inner-main">
                          <button
                            onClick={() =>
                              navigate(
                                `/${locale}/booking/doctors/${clinicCode}/${clinicId}${searchParams}`
                              )
                            }
                            className="btn back-button"
                          >
                            <i className="fas fa-arrow-left filled"></i>
                            <span>{t("back")}</span>
                          </button>
                        </div>
                      </div>

                      {/* Quiz */}
                      {slotTime ? (
                        quizData ? (
                          <>
                            <div className="appoi-detail-inner-right">
                              <h3>{clinicData?.clinicName}</h3>
                              {policyData.map((item, index) => {
                                return (
                                  <div key={index} className="my-4">
                                    <h4>{item.policy_title}</h4>
                                    <div className="appoi-detail-buttons">
                                      {item.policy_description}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            {quizData.map((item, quizIndex) => {
                              return (
                                currentQuestion >= quizIndex + 1 && (
                                  <div
                                    key={quizIndex}
                                    id={item?.linkId}
                                    className="appoi-detail-inner-right"
                                  >
                                    <h3>{item.question}</h3>
                                    <div className="appoi-detail-buttons">
                                      {item.options.map((option, index) => {
                                        const isAvailable = isTypeAvailable(
                                          option,
                                          get(quizData[0], "ans.code"),
                                          dateDayNum()
                                        );
                                        const isDisabled =
                                          quizIndex === 1 && !isAvailable;
                                        const Wrapper = isDisabled
                                          ? UnavailableTooltip
                                          : React.Fragment;
                                        return (
                                          <Wrapper key={index}>
                                            <div>
                                              <button
                                                type="button"
                                                className={`border btn w-100 ${
                                                  item?.ans?.code ===
                                                    option.code && `activeBtn`
                                                }`}
                                                onClick={() => {
                                                  checkAnswer(
                                                    item,
                                                    option,
                                                    quizIndex + 1
                                                  );
                                                }}
                                                disabled={isDisabled}
                                              >
                                                {option.description}
                                              </button>
                                            </div>
                                          </Wrapper>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )
                              );
                            })}
                          </>
                        ) : null
                      ) : null}
                      {slotTime ? (
                        !(quizJsonData(t).length + 1 > currentQuestion) ? (
                          <div id="form" className="appoi-detail-inner-right">
                            <BookingForm
                              searchDetails={searchDetails}
                              handlePostCodeChange={handlePostCodeChange}
                              promiseAddressOptions={promiseAddressOptions}
                              bookingDetails={bookingDetails}
                              setBookingDetails={setBookingDetails}
                              validated={validated}
                              handleSubmit={handleSubmit}
                              birthdate={birthdate}
                              setBirthDate={setBirthDate}
                              handleDateChange={handleDateChange}
                              bDate={bDate}
                              handleRetrieve={handleRetrieve}
                              isRetrieve={quizData?.[0]?.ans?.code === "Y"}
                            />
                          </div>
                        ) : null
                      ) : null}
                      <div>
                        {!slotTime ? (
                          slotGroups.length > 0 ? (
                            slotGroups.map((data, index) => (
                              <BookingSlots
                                key={index}
                                clinicId={clinicId}
                                clinicCode={clinicCode}
                                slotGroup={data}
                              />
                            ))
                          ) : isLoading ? (
                            <div className="main-loader">
                              <Spinner />
                            </div>
                          ) : (
                            <div>{t("no_appointments_available")}</div>
                          )
                        ) : null}
                      </div>
                    </>
                  )}
                  {step === 1 && (
                    <SendVerification
                      setStep={setStep}
                      loaders={loaders}
                      setLoaders={setLoaders}
                      setApiError={setApiError}
                      verify={verify}
                      setVerify={setVerify}
                    />
                  )}
                  {step === 2 && (
                    <VerifyPhone
                      setStep={setStep}
                      loaders={loaders}
                      setLoaders={setLoaders}
                      setApiError={setApiError}
                      verify={verify}
                      setVerify={setVerify}
                      setPatientList={setPatientList}
                    />
                  )}
                  {step === 3 && (
                    <SelectPatient
                      setStep={setStep}
                      patientList={patientList}
                      selectedPatient={selectedPatient}
                      setSelectedPatient={setSelectedPatient}
                      validated={validated}
                      handleSubmit={handleExistingSubmit}
                      setVerify={setVerify}
                      loaders={loaders}
                    />
                  )}
                </div>
              ) : (
                <>
                  <div className="card appointment-details-cnf">
                    <div className="appoi-detail-inner">
                      <h5 className="">{t("confirm_details_QA")}</h5>
                      <div className="row">
                        <div className="col-md-3 col-sm-6">
                          <strong>{t("appointment_type")}</strong>
                          <p>{quizData[1]?.ans?.description}</p>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <strong>{t("time")}</strong>
                          <p>{convertTime(slotTime)}</p>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <strong>{t("date")}</strong>
                          <p>
                            {dateLanguage(
                              longDateFormat(convertDate(slotTime).toString()),
                              locale
                            )}
                            {/* {formatDateString(slotTime)} */}
                          </p>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <strong>{t("doctor")}</strong>
                          <p>{doctorData?.doctorName}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <strong className="">{t("clinic")}</strong>
                        <p className="mb-0">
                          {clinicData?.clinicName}
                          <span>, {clinicData?.address}</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  {!selectedPatient.patientId ||
                  selectedPatient.patientId === "other" ? (
                    <div className="card personal-detail-cnf">
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <strong className="">{t("first_name")}</strong>
                          <p className="">{bookingDetails.firstName}</p>
                        </div>

                        <div className="col-md-4 col-sm-6">
                          <strong className="">{t("last_name")}</strong>
                          <p className="">{bookingDetails.lastName}</p>
                        </div>

                        <div className="col-md-4 col-sm-6">
                          <strong className="">{t("birth_date_simple")}</strong>
                          <p className="">{birthdate}</p>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <strong className="">{t("phone_number")}</strong>
                          <p className="">{bookingDetails.phoneNumber}</p>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <strong className="">{t("email")}</strong>
                          <p className="">{bookingDetails.emailAddress}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <strong className="">{t("address")}</strong>
                          <div className="">
                            <p className="mb-0">
                              {bookingDetails.streetAddress}
                              <span className="d-inlin-block ms-1">
                                {searchDetails.postcode.label},{" "}
                                {searchDetails.postcode.state},{" "}
                                {searchDetails.postcode.postcode}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="card appointment-details-cnf">
                        <div className="appoi-detail-inner">
                          <b>{t("appointment_book_for")} :</b>{" "}
                          <span className="d-inline fw-bold primary-text-color">
                            {selectedPatient.firstName +
                              " " +
                              selectedPatient.lastName}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="d-flex justify-content-between appoi-detail-section-button card-appointment-button">
                    <button
                      className="btn btn-primary bg-secondary border-secondary px-5"
                      onClick={handleGoBack}
                    >
                      {" "}
                      <img src="/assets/images/back-arrow.png" alt="arrow" />
                      {t("go_back_make_change")}
                    </button>
                    <button
                      className="btn btn-outline-primary app-confirm-btn"
                      onClick={handleCheckContinue}
                    >
                      {confirmBtnLoading ? (
                        <Loader color="light" size="sm" />
                      ) : (
                        t("confirm_details")
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={checkLength}
        onHide={handleCheckLengthClose}
        className="ct-app-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            {t("type_unavailable")}{" "}
            <span>
              {slotTime && convertTime(slotTime).toString()}{" "}
              {slotTime &&
                dateLanguage(
                  longDateFormat(convertDate(slotTime).toString()),
                  locale
                )}
              {/* {slotTime && formatDateString(slotTime)} */}
            </span>
            {t("appt_type_message")}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="ct-apt-btn"
            onClick={() =>
              navigate(
                `/${locale}/booking/doctors/${clinicCode}/${clinicId}${searchParams}`
              )
            }
          >
            {t("yes")}
          </Button>
          <Button
            className="ct-apt-btn"
            onClick={() => handleCheckLengthClose()}
          >
            {t("no")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* change appointment modal */}
      <Modal
        show={showPractitioner}
        onHide={handleClose}
        backdrop="static"
        className="appoi-booking-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="h-100 d-flex align-items-center justify-content-center">
              <Spinner />
            </div>
          ) : (
            <div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group card-label">
                    <Form.Select
                      className="modal-input"
                      onChange={(e) => handlePractitionerChange(e)}
                      defaultValue={practitionerCode}
                      aria-label="Default select example"
                    >
                      {practitioners.map((item, index) => {
                        return (
                          <option key={index} value={item.doctorCode}>
                            {item.doctorName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group card-label m-0">
                    <Form.Control
                      className="form-control modal-input date-picker-input"
                      onChange={onDateChange}
                      type="date"
                      defaultValue={
                        slotTime && date && date?.toISOString().split("T")[0]
                      }
                      min={new Date().toISOString().split("T")[0]}
                      required
                    />
                  </div>
                </div>
              </div>
              {slotGroups && slotGroups.length !== 0 ? (
                slotGroups.map((data, index) => (
                  <BookingSlots
                    key={index}
                    clinicId={clinicId}
                    clinicCode={clinicCode}
                    slotGroup={data}
                    selectedSlot={selectedSlot}
                    setSelectedSlot={setSelectedSlot}
                    page={"slot_modal"}
                  />
                ))
              ) : (
                <div class="card appoi-not-ava appoin-date-section">
                  <h5 class="modal-header justify-content-center">
                    No appointments available
                  </h5>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="ct-apt-btn"
            onClick={handleContinue}
            disabled={isBookDisable() || isLoading}
          >
            {t("continue")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCheckAppointment}
        onHide={handleCloseAppointment}
        className="ct-app-modal ct-app-modal-2"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            {t("appt_time")}{" "}
            <span>
              {slotTime && convertTime(slotTime).toString()}{" "}
              {slotTime &&
                dateLanguage(
                  longDateFormat(convertDate(slotTime).toString()),
                  locale
                )}
              {/* {slotTime && formatDateString(slotTime)} */}
            </span>{" "}
            {t("appt_not_avail")}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="ct-apt-btn"
            variant="secondary"
            onClick={() => {
              handleGoBackSlots();
            }}
          >
            {t("yes")}
          </Button>
          <Button
            className="ct-apt-btn"
            variant="secondary"
            onClick={() => {
              navigate(`/${searchParams}`);
            }}
          >
            {t("no")}
          </Button>
        </Modal.Footer>
      </Modal>
      {showAlertModal && (
        <AlertModal
          resStatus="error"
          modalStatus={true}
          title={t("general_error_title")}
          message={`${t("general_error_prefix")}${clinicData.phoneNumber}${t(
            "general_error_suffix"
          )}`}
          closeCallback={() => setShowAlertModal(false)}
        />
      )}

      {showBlackListAlertModal && (
        <Modal
          show={showBlackListAlertModal}
          centered
          onHide={() => setShowBlackListAlertModal(false)}
        >
          <Modal.Body className="text-center rt-spacing">
            <h4 className="mb-0">{t("blacklist_message")}</h4>
          </Modal.Body>

          <Modal.Footer className="justify-content-center card-appointment-button">
            <Button
              variant="outline-primary"
              className="rt-btn"
              onClick={() => setShowBlackListAlertModal(false)}
            >
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {Object.values(apiError).includes(true) && (
        <AlertModal
          resStatus="error"
          modalStatus={true}
          title={t("warning_title")}
          message={t(apiError.phone ? "phone_error" : "code_error")}
          closeCallback={() => setApiError({ phone: false, code: false })}
        />
      )}
    </div>
  );
};

export default Slot;
