import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../common/Loader";

const SendVerification = ({
  setStep,
  loaders,
  setLoaders,
  setApiError,
  verify,
  setVerify,
}) => {
  const { t } = useTranslation();

  const handleBack = () => {
    setStep(0);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    setVerify((prev) => ({
      ...prev,
      phoneNumber: {
        value: sanitizedValue,
        isInValid: sanitizedValue.length !== 10,
      },
    }));
  };

  const handleSendVerifyMobile = () => {
    const sanitizedValue = verify.phoneNumber.value
      .replace(/[^0-9]/g, "")
      .slice(0, 10);
    if (sanitizedValue.length !== 10) {
      setVerify((prev) => ({
        ...prev,
        phoneNumber: {
          ...prev["phoneNumber"],
          isInValid: true,
        },
      }));
      return;
    }
    setLoaders((prev) => ({ ...prev, phone: true }));
    fetch(`/server/patient/sms/code`, {
      method: "PUT",
      body: JSON.stringify({ phoneNumber: verify.phoneNumber.value }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result) {
          setVerify((prev) => ({
            ...prev,
            code: {
              value: "",
              isInValid: false,
            },
          }));
          setStep(2);
          return;
        }
        setApiError((prev) => ({ ...prev, phone: true }));
      })
      .catch(() => {
        setApiError((prev) => ({ ...prev, phone: true }));
      })
      .finally(() => {
        setLoaders((prev) => ({ ...prev, phone: false }));
      });
  };

  return (
    <>
      <div className="appoi-nav-main">
        <div className="appoi-nav-inner-main">
          <button onClick={handleBack} className="btn back-button">
            <i className="fas fa-arrow-left filled"></i>
            <span>{t("back")}</span>
          </button>
        </div>
      </div>
      <div className="appoi-detail-inner-right">
        <h4 className="mb-4">{t("phone_number_heading")}</h4>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Form.Label className="text-center">{t("phone_number")} *</Form.Label>
          <Form.Control
            className="form-control"
            style={{
              width: window.innerWidth >= 768 ? "25%" : "50%",
            }}
            onChange={handlePhoneNumberChange}
            value={verify.phoneNumber.value}
            name="phoneNumber"
            type="text"
            required
            autoFocus
            isInvalid={verify.phoneNumber.isInValid}
          />
          <Form.Control.Feedback type="invalid" className="w-100 text-center">
            {t("phone_number_err_message")}
          </Form.Control.Feedback>
          <button
            type="button"
            className="border btn activeBtn verify-btn text-white my-3"
            style={{ minWidth: "100px" }}
            onClick={handleSendVerifyMobile}
          >
            {loaders.phone ? <Loader color="light" size="sm" /> : t("continue")}
          </button>
        </div>
      </div>
    </>
  );
};

export default SendVerification;
