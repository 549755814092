let en = {
  translations: {
    home: "Home",
    checkin: "Check-in",
    manage_booking: "Manage Appointments",
    back: "Back",
    change: "Change",
    about_practice: "About this practice",
    billing_policy: "Billing policy",
    billing_policy_description: `All patients are required to pay upfront for their
    consultation, with an out of pocket cost of $35.00 for
    standard appointments. We will then submit the claim
    to Medicare directly. Children under 16 with a valid
    Medicare card will not pay a gap and concession card
    holders will pay a discounted rate.`,
    cancellation_policy: "Cancellation / Non-attendance policy",
    cancellation_policy_description: `If you can no longer attend the appointment, please
    cancel or reschedule through this website or calling
    our receptionist. Please note a fee of $39.75 will
    apply if you cancel less than 2 hours prior to your
    appointment or fail to attend your appointment.`,
    question1: `Who is this appointment for?`,
    question1opt1: `Myself`,
    question1opt2: `Someone else`,
    question2: `Has the patient attended our clinic before?`,
    question2opt1: `No (new to this clinic)`,
    question2opt2: `Yes (existing patient)`,
    question3: `What type of appointment do you need?`,
    about_you: "Patient information",
    first_name: "First Name",
    first_name_err_message: "First Name should not be empty.",
    last_name: "Last Name",
    last_name_err_message: "Last Name should not be empty.",
    birth_date: "Date of Birth",
    birth_date_simple: "Date of Birth",
    birth_date_err_message: "Please enter valid date",
    phone_number: "Phone number",
    phone_number_err_message: "Please Enter Valid Phone Number",
    email: "Email",
    email_err_message: "Please Enter Valid Email",
    address: "Address",
    street_address: "Street address",
    street_address_err_message: "Address should not be empty",
    suburb: "Suburb",
    suburb_err_message: "Suburb should not be empty",
    postcode: "Postcode",
    postcode_err_message: "Please Enter valid PostCode",
    read_and_accept_term_and_condition: `I have read and accept the Terms & Conditions`,
    read_and_accept_term_and_condition_feedback: `You must agree before submitting.`,
    accept_term_and_condition: `Please accept terms & Conditions`,
    continue: "Continue",
    more_appointment: "More appointments",
    appointment_confirmed: `Your appointment is confirmed`,
    forward_medical_gp_clinic: `Forward Medical GP Clinic`,
    close: "Close",
    help_and_support: "Help and Support",
    privacy_and_policy: "Privacy Policy",
    term_of_use: "Terms of use",
    all_right_reserved: `2023 NextAppointment`,
    no_appointment_available: "No appointments available",
    morning: "Morning",
    afternoon: "Afternoon",
    speak: "Speaks ",
    type_unavailable: "Sorry that type of appointment isn't available at ",
    appt_type_message:
      ". Please select a different time for that appointment type.",
    appt_time: "Sorry appointment time at ",
    appt_not_avail:
      "is no longer available, please select a different appointment time ?",
    book_your_next: "Book your",
    dowanload_app_book_your_next: "Book your next appointment faster with",
    dowanload_app_appointment: "Next Appointment",
    scan_and_dowanload_app: "Scan the QR code to download the app now",
    dowanload_app_main_book_your_favorite:
      "Book your next appointment faster with",
    doanload_app_main_pactitioners: " the",
    dowanload_app_main_app: "app",
    dowanload_app_main_availabe: "Available on iOS and Android",
    healthcare_appointment: "next appointment easier",
    find_pactitioners: "Time to connect your healthcare dream team",
    book_appointment_now: "Book Appointment Now",
    search: "Search",
    dowanload_app_button: "Dowanload the App",
    practices: "Practices",
    practitioners: "Practitioners",
    popular_practices: "Popular Practices",
    at: "at",
    yes: "OK",
    no: "Cancel",
    next: "Next",
    no_appointments_available: "No appointments available",
    general_error_title: "System Error",
    warning_title: "Information",
    general_error_prefix:
      "Sorry we have difficulties to connect to your clinic's booking system, please contact your clinic at ",
    general_error_suffix: " to book an appointment.",
    we_speak_your_language: "We speak your language",
    confirm_details_QA: "Confirm your appointment details",
    appointment_type: "Appointment Type",
    time: "Time",
    date: "Date",
    doctor: "Doctor",
    clinic: "Clinic",
    go_back_make_change: "Edit details",
    confirm_details: "Confirm",
    set: "Set",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    day_abbr: "Day",
    month_abbr: "Month",
    year_abbr: "Year",
    select_option: "Please enter suburb",
    state: "state",
    privacy_policy: "Privacy Policy",
    p_intro: "Introduction",
    p_introduction:
      "This Privacy Policy sets out how and why we collect, stores, uses and discloses your personal information, and how to contact us if you have any questions about how we handle your personal information.",
    p_information: "What personal information do we collect?",
    p_info_collect: "The information we will collect about you includes:",
    your_name: "Your Name",
    your_DOB: "Your date of birth",
    your_contact_detail:
      "Your contact details (e.g. address, email address, phone number)",
    your_gender: "Your gender and/or birth sex",
    occupation: "Occupation",
    cultural_bg: "Cultural background",
    your_emg_detail: "Your emergency contact details",
    your_kin_detail: "Your next of kin details",
    your_app_request: "The type of appointment you are requesting",
    your_app_reason: "The reason you are seeking that type of appointment",
    your_notes:
      "Notes, records and images you provide relating to your health appointments",
    your_health:
      "Information about your private health insurance fund, including your membership number",
    your_medicare:
      "Your Medicare, Pension, Health Care Card and Veteran Affairs number and details",
    q_anonymous: "Dealing with us anonymously?",
    ans_anonymous:
      " You have the right to deal with us anonymously or under a pseudonym unless it is impracticable for us to do so, or unless we are required or authorised by law to only deal with identified individuals.",
    ps_info: "How do we collect your personal information",
    ans_info: "Through our website, and associated booking applications.",
    ps_share_info: "Who do we share your personal information with?",
    ans_share_info:
      "We only share your personal information with the practice that you book your appointment with at the time of your booking. No information will be shared or stored after you finish your bookings.",
    ps_protect: "How do we store and protect your personal information?",
    ans_protect:
      "Your personal information is stored electronically at your practice or your personal device that you use to book appointments. We do not store any of your personal information in our server, all information that transmits from your device and your practice are encrypted and discarded after your booking is confirmed or not proceeded.",
    your_complaint:
      "How can you lodge a privacy related complaint, and how will the complaint be handled?",
    ans_complaint_1:
      "We take complaints and concerns regarding privacy seriously. You should express any privacy concerns you may have in writing. We will then attempt to resolve it in accordance with our resolution procedure. Please write to us via email ",
    ans_complaint_2:
      ", mail (Privacy Officer, 50 Hutt Street Adelaide 5000). We will process your complaint within 30 days, and reply in writing to you.",
    contact_info_1:
      "You may also contact the OAIC. Generally, the OAIC will require you to give them time to respond before they will investigate. For further information visit",
    contact_info_2: "or call the OAIC on 1300 336 002.",
    manage_appointment: "Manage Appointments",
    book_appointment: "Book Appointment",
    appointments: "Appointments",
    with: "With",
    for: "for",
    check_in: "Check In",
    confirm_appointment: "Confirm Appointment",
    cancel_appointment: "Cancel Appointment",
    confirm_cancellation: "Confirm Cancellation",
    contact_clinic_msg: "Your appointment could not be found！",
    account_list_msg: "Your account information could not be found！",
    required_msg: "This field is required!",
    submit: "Submit",
    confirm_msg: "Confirm appointment attendance",
    thank_you_msg: "Your appointment has been successfully confirmed!",
    cancel_confirm_msg: "Your appointment has been successfully cancelled!",
    review_msg:
      "Please let us know as soon as your circumstances is clear, our friendly receptionist may give you a call before your appointment.",
    not_sure: "Return",
    confirm_ans: "Confirm appointment cancellation",
    keep_appointment: "Keep Appointment",
    thank_You_check_in:
      "Thank you for checking in. Please take a seat in the waiting room and your doctor will see you soon.",
    assist_msg:
      "Based on your responses, you must see the reception desk for check-in.",
    total: "Total",
    invoice_res_1:
      "The requested invoice has been sent to your registered email.",
    invoice_res_2:
      "We are currently unable to process your request. Please contact us directly for assistance.",
    invoice_res_3:
      "Please contact us directly to register your email. This must be completed before requesting any invoices",
    invoice_res_4:
      "We where unable to find your invoice history. Please contact us directly for assistance",
    clinic_no_found:
      "Sorry we could not find this clinic in our system. Please contact us directly for assistance",
    account: "Invoice History",
    account_list: "Invoice History",
    invoice_id: "Invoice Id",
    provider: "Provider",
    service: "Service",
    mbs_item: "MBS Item",
    fee: "Fee",
    paid: "Paid",
    email_a_copy: "Email",
    bulk_billed: "Bulk Billed",
    unavailable_tooltip:
      "This appointment cannot be booked online, please contact us directly.",
    blacklist_message:
      "Internal Server Error server (500), please try again later.",
    status: "Status",
    label_yes: "Yes",
    label_no: "No",
    has_patient_phone:
      "Enter the phone number and verify to automatically complete the form below.",
    phone_number_heading:
      "Please enter your phone number to automatically retrieve the patient’s information.",
    retrieve: "Retrieve",
    verification_code: "Please enter the code below.",
    verification_code_err_message: "Please enter valid verification code",
    send_verification_code: "Send Verification Code",
    verify: "Verify",
    code_error: "The verification code provided was incorrect, please check.",
    phone_error:
      "Sorry there is an unexpected error, please contact your clinic for assistance.",
    text_message_sent: "A text message has been sent to",
    with_verification_code: "with a verification code",
    change_phone_number: "Change phone number",
    enter_patient_info: "Enter patient information manually",
    select_patient:
      "Please select the patient that you wish to make a booking for.",
    other_patient: "Other Patient",
    appointment_book_for: "Patient",
    no_patient_found: "No Patient Found",
  },
};

export default en;
